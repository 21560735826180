import { getRoutingStatistics } from '@/services/api/analytics/analytics'

export default {
  namespaced: true,
  state: {
    routingStatistics: null,
    loading: false,
  },
  mutations: {
    SET_ROUTING_STATISTICS(state, payload) {
      state.routingStatistics = payload
    },
    SET_LOADING(state, loading) {
      state.loading = loading
    },
  },
  actions: {
    async getRoutingStatistics({ commit }, payload) {
      commit('SET_LOADING', true)
      const response = await getRoutingStatistics(payload)
      const chartData = calculateRoutingStatisticData(response.data)
      commit('SET_ROUTING_STATISTICS', chartData)
      commit('SET_LOADING', false)
    },
  },
  getters: {},
}

function calculateRoutingStatisticData(routingStatistics) {
  let xValues = ['x']
  let totalRoutesData = {
    title: 'Total Routes',
    xValues: ['x'],
    yValues: [['Fixed Routes'], ['Specialized']],
    total: 0,
    unit: 'routes',
  }

  let totalRouteLengthData = {
    title: 'Total Route Length',
    xValues: ['x'],
    yValues: [['Fixed Routes'], ['Specialized']],
    total: 0,
    unit: 'hours',
  }

  let avgRouteLengthData = {
    title: 'Ave. Route Length',
    xValues: ['x'],
    yValues: [['Fixed Routes'], ['Specialized']],
    total: 0,
    unit: 'mins',
  }

  let totalRidershipData = {
    title: 'Total Ridership',
    xValues: ['x'],
    yValues: [['Fixed Routes'], ['Specialized']],
    total: 0,
    unit: 'students',
  }

  let avgRidershipData = {
    title: 'Ave. Ridership',
    xValues: ['x'],
    yValues: [['Fixed Routes'], ['Specialized']],
    total: 0,
    unit: 'students',
  }

  let totalStopCountData = {
    title: 'Total Stops Count',
    xValues: ['x'],
    yValues: [['Fixed Routes'], ['Specialized']],
    total: 0,
    unit: 'stops',
  }

  let avgStopCountData = {
    title: 'Ave. Stops Count',
    xValues: ['x'],
    yValues: [['Fixed Routes'], ['Specialized']],
    total: 0,
    unit: 'stops',
  }

  for (let i = 0; i < routingStatistics.length; i++) {
    const dayStas = routingStatistics[i]
    xValues.push(dayStas.date)

    totalRoutesData.yValues[0].push(dayStas.routingCount)
    totalRoutesData.yValues[1].push(dayStas.specializedRoutingCount)

    totalRouteLengthData.yValues[0].push(
      (dayStas.totalRoutingsLength / 60).toFixed(2),
    )
    totalRouteLengthData.yValues[1].push(
      (dayStas.totalSpecializedRoutingsLength / 60).toFixed(2),
    )

    avgRouteLengthData.yValues[0].push(dayStas.avgRoutingsLength.toFixed(2))
    avgRouteLengthData.yValues[1].push(
      dayStas.avgSpecializedRoutingsLength.toFixed(2),
    )

    totalRidershipData.yValues[0].push(dayStas.routingStudents)
    totalRidershipData.yValues[1].push(dayStas.specializedRoutingStudents)

    avgRidershipData.yValues[0].push(dayStas.avgRoutingStudents.toFixed(2))
    avgRidershipData.yValues[1].push(
      dayStas.avgSpecializedRoutingStudents.toFixed(2),
    )

    totalStopCountData.yValues[0].push(dayStas.routingStopCount)
    totalStopCountData.yValues[1].push(dayStas.specializedRoutingStopCount)

    avgStopCountData.yValues[0].push(dayStas.avgRoutingStopCount.toFixed(2))
    avgStopCountData.yValues[1].push(
      dayStas.avgSpecializedRoutingStopCount.toFixed(2),
    )

    if (i === routingStatistics.length - 1) {
      totalRoutesData.total =
        dayStas.routingCount + dayStas.specializedRoutingCount
      totalRouteLengthData.total = (
        (dayStas.totalRoutingsLength + dayStas.totalSpecializedRoutingsLength) /
        60
      ).toFixed(2)
      avgRouteLengthData.total = (
        (dayStas.avgRoutingsLength + dayStas.avgSpecializedRoutingsLength) /
        2
      ).toFixed(2)
      totalRidershipData.total =
        dayStas.routingStudents + dayStas.specializedRoutingStudents
      avgRidershipData.total = (
        (dayStas.avgRoutingStudents + dayStas.avgSpecializedRoutingStudents) /
        2
      ).toFixed(2)
      totalStopCountData.total =
        dayStas.routingStopCount + dayStas.specializedRoutingStopCount
      avgStopCountData.total = (
        (dayStas.avgRoutingStopCount + dayStas.avgSpecializedRoutingStopCount) /
        2
      ).toFixed(2)
    }
  }

  avgRouteLengthData.xValues = xValues
  totalRoutesData.xValues = xValues
  totalRouteLengthData.xValues = xValues
  avgRidershipData.xValues = xValues
  totalRidershipData.xValues = xValues
  avgStopCountData.xValues = xValues
  totalStopCountData.xValues = xValues

  return [
    totalRoutesData,
    totalRouteLengthData,
    avgRouteLengthData,
    totalRidershipData,
    avgRidershipData,
    totalStopCountData,
    avgStopCountData,
  ]
}
