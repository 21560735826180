import Vue from 'vue'
import Vuex from 'vuex'
import router from '@/router'
import * as jwt from '@/services/auth'

const Auth = {
  login: jwt.login,
  register: jwt.register,
  verifyEmailCode: jwt.verifyEmailCode,
  verifyToken: jwt.verifyToken,
  resendCode: jwt.resendCode,
  resetPassword: jwt.resetPassword,
  currentAccount: jwt.currentAccount,
  forgotPassword: jwt.forgotPassword,
  logout: jwt.logout,
}
Vue.use(Vuex)

export default {
  namespaced: true,
  state: {
    id: '',
    name: '',
    first_name: '',
    last_name: '',
    user: null,
    role: '',
    email: '',
    phone: '',
    avatar: '',
    authorized: false,
    loading: false,
    userId: '',
    organization: null,
  },
  mutations: {
    SET_STATE(state, payload) {
      Object.assign(state, {
        ...payload,
      })
    },
  },
  actions: {
    LOGIN({ commit, dispatch }, { payload }) {
      commit('SET_STATE', {
        loading: true,
      })
      const login = Auth.login
      login(payload).then((response) => {
        if (!response) {
          Vue.prototype.$notification.warning({
            message: 'Login failed',
            description: 'Invalid user name or passowrd.',
          })
          commit('SET_STATE', {
            loading: false,
          })
          return
        }
        const { success, userId, accessToken } = response
        if (success) {
          Vue.prototype.$notification.success({
            message: 'Logged In',
            description: 'You have successfully logged in to Routr.',
          })
          localStorage.setItem('accessToken', accessToken.accessToken)
          // Vue.prototype.$notification.success({
          //   message: 'Verification email sent.',
          //   description: 'Check your email for the verification code.',
          // })
          commit('SET_STATE', {
            loading: false,
            authorized: true,
          })
          dispatch('LOAD_CURRENT_ACCOUNT')

          router.push('/tools/contracts/all')
        } else {
          Vue.prototype.$notification.warning({
            message: 'Login failed',
            description: 'Invalid user name or passowrd.',
          })
          commit('SET_STATE', {
            loading: false,
          })
        }
      })
    },
    RESET_PASSWORD({ commit, state }, { password }) {
      commit('SET_STATE', {
        loading: true,
      })
      const userId = state.userId
      const resetPassword = Auth.resetPassword
      resetPassword({ userId, password })
        .then((res) => {
          if (res.data.success) {
            Vue.prototype.$notification.success({
              message: 'Success',
              description: 'You have successfully changed the password',
            })
            commit('SET_STATE', {
              loading: false,
            })
            router.push('/auth/login')
          }
        })
        .catch((error) => {
          const errorMessage = error.response.data.error
          console.log(errorMessage)
        })
        .finally(() =>
          commit('SET_STATE', {
            loading: false,
          }),
        )
    },
    REGISTER({ commit }, { payload }) {
      commit('SET_STATE', {
        loading: true,
      })
      const register = Auth.register
      register(payload)
        .then((res) => {
          if (res.data.success) {
            Vue.prototype.$notification.success({
              message: 'Success',
              description: 'You are successfully registered.',
            })
            commit('SET_STATE', {
              loading: false,
            })
            router.push('/auth/login')
          }
        })
        .catch((error) => {
          const errorMessage = error.response.data.error
          if (errorMessage == 'Conflict') {
            commit(
              'notification/NEW',
              {
                type: 'warning',
                message: 'Get Error',
                description: 'Your email is already signed up',
              },
              { root: true },
            )
          }
        })
        .finally(() =>
          commit('SET_STATE', {
            loading: false,
          }),
        )
    },
    LOAD_CURRENT_ACCOUNT({ commit }) {
      commit('SET_STATE', {
        loading: false,
      })
      const currentAccount = Auth.currentAccount

      currentAccount().then((response) => {
        if (response) {
          localStorage.setItem('timeZone', response.organization.timeZone)
          const {
            id,
            email,
            firstName: first_name,
            lastName: last_name,
            role,
            avatar,
            phone,
            organization,
          } = response
          commit('SET_STATE', {
            id,
            first_name,
            last_name,
            email,
            avatar,
            role: 'ADMIN',
            phone,
            organization,
            loading: false,
            authorized: true,
          })
        } else {
          commit('SET_STATE', {
            loading: false,
            authorized: false,
          })
          const currentURL = window.location.href
          if (!currentURL.split('?')[0].includes('/forgot-password-submit')) {
            router.push('/auth/login')
          }
        }
      })
    },
    VERIFY_FORGOT_PASSWORD_TOKEN({ commit, dispatch }, { payload }) {
      const token = payload
      const verifyToken = Auth.verifyToken
      if (token) {
        verifyToken(token).then((response) => {
          const { success, accessToken, userId } = response.data
          if (success) {
            localStorage.setItem('accessToken', accessToken.accessToken)
            router.push({
              path: '/auth/forgot-password-submit',
            })
            commit('SET_STATE', {
              loading: false,
              userId: userId,
            })
          } else {
            Vue.prototype.$notification.warning({
              message: 'Failed',
              description: 'Session has been expired. Please try again.',
            })
            router.push('/auth/forgot-password')
          }
        })
      } else {
        Vue.prototype.$notification.warning({
          message: 'Failed',
          description: 'Token is invalid.',
        })
        router.push('/auth/forgot-password')
      }
    },
    LOGOUT({ commit }) {
      localStorage.removeItem('timeZone')
      const logout = Auth.logout
      logout().then(async () => {
        commit('SET_STATE', {
          id: '',
          first_name: '',
          last_name: '',
          name: '',
          role: '',
          email: '',
          avatar: '',
          organization: null,
          authorized: false,
          loading: false,
          payload: null,
        })
        router.push('/auth/login')
      })
    },
    RESEND_CODE({ state }) {
      const userId = state.userId
      const resendCode = Auth.resendCode
      resendCode({ userId }).then((success) => {
        if (success) {
          Vue.prototype.$notification.success({
            message: 'Success',
            description: 'Verification code has been sent again.',
          })
        } else {
          Vue.prototype.$notification.warning({
            message: 'Failed',
            description: 'Session has been removed. Please sign in again.',
          })
          router.push('/auth/login')
        }
      })
    },
    VERIFY_OTP({ commit, state, dispatch }, { payload }) {
      const { code, trustDevice } = payload
      const userId = state.userId

      const verifyEmailCode = Auth.verifyEmailCode

      verifyEmailCode({ code, userId, trustDevice }).then((response) => {
        if (!response) {
          Vue.prototype.$notification.warning({
            message: 'Failed',
            description: 'Invalid verification code.',
          })
          commit('SET_STATE', {
            loading: false,
          })
          return
        }
        const { success, accessToken } = response
        localStorage.setItem('accessToken', accessToken)
        if (success) {
          Vue.prototype.$notification.success({
            message: 'Logged In',
            description: 'You have successfully logged in to Routr.',
          })
          commit('SET_STATE', {
            loading: false,
            authorized: true,
          })
          dispatch('LOAD_CURRENT_ACCOUNT')
          router.push('/tools/contracts/all')
        } else {
          Vue.prototype.$notification.warning({
            message: 'Failed',
            description: 'Invalid verification code.',
          })
          commit('SET_STATE', {
            loading: false,
          })
        }
      })
    },
    FORGOT_PASSWORD({ commit, rootState }, { payload }) {
      const { email } = payload
      const forgotPassword = Auth.forgotPassword
      forgotPassword(email).then((success) => {
        if (success) {
          Vue.prototype.$notification.success({
            message: 'Password reset link sent',
            description: 'Please check your email for the password reset code.',
          })
          commit('SET_STATE', {
            loading: false,
            authorized: false,
          })
          router.push({
            path: '/auth/forgot-password-resend',
            query: { email: payload.email },
          })
        }
        if (!success) {
          commit('SET_STATE', {
            loading: false,
          })
        }
      })
    },
  },
  getters: {
    user: (state) => state,
  },
}
