import {
  getTelematicsByFilter,
  listAllTelematics,
} from '@/services/api/telematics/telematics'

export default {
  namespaced: true,
  state: {
    centerLocation: [-72.805293, 40.821545],
    selectedVehicle: {},
    selectedTelematicsData: [],
    allTelematics: [],
    filteredTelematics: [],
    reports: [],
    reportsByFilter: [],
    loading: false,
    filter: {
      vehicleType: '',
      vehicleClass: '',
      assignedDepot: '',
      status: '',
    },
  },
  mutations: {
    SET_CENTER_LOCATION(state, payload) {
      const coordinatesArray = payload.split(',')
      state.centerLocation = coordinatesArray.reverse()
    },
    SET_SELECT_VEHICLE(state, payload) {
      state.selectedVehicle = payload
      state.selectedTelematicsData = []
      state.selectedTelematicsData.push(payload)
    },
    UNSET_SELECT_VEHICLE(state, payload) {
      state.selectedVehicle = payload
      state.selectedTelematicsData = []
    },
    SET_SELECTED_TELEMATICS(state, payload) {
      if (state.selectedTelematicsData.length >= 2) {
        state.selectedTelematicsData.shift()
        state.selectedTelematicsData.push(payload)
      } else {
        state.selectedTelematicsData.push(payload)
      }
    },
    SET_LOCATION(state, payload) {
      state.centerLocation = payload
    },
    SET_TELEMATICS(state, payload) {
      state.allTelematics = payload
      const { vehicleType, vehicleClass, assignedDepot, status } = state.filter
      let filtered = state.allTelematics
      if (vehicleType) {
        filtered = filtered.filter(item => item.vehicle.vehicleType === vehicleType)
      }
      if (vehicleClass) {
        filtered = filtered.filter(item => item.vehicle.vehicleClass === vehicleClass)
      }
      if (assignedDepot) {
        filtered = filtered.filter(item => item.vehicle.assignedDepot === assignedDepot)
      }
      if (status) {
        const statusValue = status === 'On'? true : false;
        filtered = filtered.filter(item => item.ignition === statusValue)
      }
      state.filteredTelematics = filtered
    },
    SET_REPORTS(state, payload) {
      state.reports = payload
      state.reportsByFilter = payload
    },
    SET_REPORTS_BY_FILTER(state, payload) {
      state.reportsByFilter = payload
    },
    UPDATE_VEHICLE_FILTER(state, payload) {
      state.filter = { ...payload }
      const { vehicleType, vehicleClass, assignedDepot, status } = payload
      let filtered = state.allTelematics
      if (vehicleType) {
        filtered = filtered.filter(item => item.vehicle.vehicleType === vehicleType)
      }
      if (vehicleClass) {
        filtered = filtered.filter(item => item.vehicle.vehicleClass === vehicleClass)
      }
      if (assignedDepot) {
        filtered = filtered.filter(item => item.vehicle.assignedDepot === assignedDepot)
      }
      if (status) {
        const statusValue = status === 'On'? true : false;
        filtered = filtered.filter(item => item.ignition === statusValue)
      }
      state.filteredTelematics = filtered
    },
    SET_LOADING(state, loading) {
      state.loading = loading
    },
  },
  actions: {
    async setCenterLocation(state, payload) {
      state.commit('SET_CENTER_LOCATION', payload)
    },

    async setSelectVehicle(state, payload) {
      state.commit('SET_SELECT_VEHICLE', payload)
    },

    async unsetSelectVehicle(state, payload) {
      state.commit('UNSET_SELECT_VEHICLE', payload)
    },

    async updateVehicleFilter(state, payload) {
      state.commit('UPDATE_VEHICLE_FILTER', payload)
    },

    async setSelectedTelematics(state, payload) {
      state.commit('SET_SELECTED_TELEMATICS', payload)
    },

    async setLocation(state, payload) {
      state.commit('SET_LOCATION', payload)
    },

    async listAllTelematics({ commit }, payload) {
      commit('SET_LOADING', true)
      return listAllTelematics()
        .then((telematics) => {
          commit('SET_TELEMATICS', telematics.data)
        })
        .catch((error) => {
          console.error(error)
          commit(
            'notification/NEW',
            {
              type: 'error',
              message: 'Fetch Error',
              description: 'Faild to get telematics data',
            },
            { root: true },
          )
        })
        .finally(() => commit('SET_LOADING', false))
    },

    async getReports({ commit }, payload) {
      commit('SET_LOADING', true)
      return getTelematicsByFilter(payload)
        .then((reports) => {
          commit('SET_REPORTS', reports.data)
        })
        .catch((error) => {
          console.error(error)
          commit(
            'notification/NEW',
            {
              type: 'error',
              message: 'Fetch Error',
              description: 'Faild to get reports data',
            },
            { root: true },
          )
        })
        .finally(() => commit('SET_LOADING', false))
    },
    async setReportsByFilter({ commit }, payload) {
      return commit('SET_REPORTS_BY_FILTER', payload)
    },
    async setReports({ commit }, payload) {
      return commit('SET_REPORTS', payload)
    },
  },
}
